var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-format-document",attrs:{"title":_vm.formatId ? 'EDIT FORMAT' : 'ASSIGN A NEW FORMAT',"title-tag":"h3","modal-class":"modal-primary","no-close-on-backdrop":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button-cancel',{on:{"click":function($event){return cancel()}}}),_c('button-save',{on:{"click":function($event){return _vm.save()}}})]}}])},[_c('validation-observer',{ref:"form"},[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name:"}},[_c('validation-provider',{attrs:{"rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"255","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Name "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Document Type:"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors[0] ? 'select__error' : '',attrs:{"clearable":"","label":"name","placeholder":"Select document Type...","options":_vm.G_DOCUMENT_ACTIVES,"reduce":function (option) { return option.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.form.document_type),callback:function ($$v) {_vm.$set(_vm.form, "document_type", $$v)},expression:"form.document_type"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Document Type "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Document File:"}},[_c('validation-provider',{attrs:{"rules":_vm.formatId ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[(!_vm.file_check && _vm.fileExtension == 'pdf')?_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Preview File'),expression:"'Preview File'",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":_vm.openPreviewPdf}})],1):_vm._e(),_c('b-form-file',{class:errors[0] || _vm.file_check ? 'select__error' : '',attrs:{"state":errors.length > 0 ? false : null,"accept":".pdf"},on:{"change":_vm.loadFile},model:{value:(_vm.form.document_file),callback:function ($$v) {_vm.$set(_vm.form, "document_file", $$v)},expression:"form.document_file"}})],1),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Document File "+_vm._s(errors[0]))]):_vm._e(),(_vm.file_check)?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Only PDF is accepted")]):_vm._e()]}}])})],1)],1)],1)],1),(_vm.showPreviewPdf)?_c('preview-pdf',{attrs:{"pdf-file":_vm.pdfFile,"pdf-name":_vm.pdfName,"pdfUrl":_vm.pdfFileUrl},on:{"close":function($event){_vm.showPreviewPdf = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }