const DocumentFormatFields = [
    {
        key: 'name',
        label: 'Name',
        sortable: true,
    },
    {
        key: 'document_type',
        label: 'Document Type',
        sortable: true,
    },
    {
        key: 'file',
        label: 'File',
        sortable: false,
    },
    {
        key: 'extension',
        label: 'extension',
        sortable: false,
    },
    {
        key: 'format_history',
        label: 'times used',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'created_by',
        label: 'Created By',
        sortable: false,
    },
    {
        key: "action",
        thClass: "text-center",
        tdClass: "text-center",
    }

];

export default {
    DocumentFormatFields,
};