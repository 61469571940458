<template>
  <div class="px-2">
    <filter-slot
      :filter="FilterSlot.filter"
      :totalRows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :startPage="FilterSlot.startPage"
      :toPage="FilterSlot.toPage"
      :filterPrincipal="FilterSlot.filterPrincipal"
      @reload="$refs['documentsFormatsTable'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button variant="primary" @click="openModalCreate()">
            <feather-icon icon="PlusIcon" size="15" class="mr-50" />Create
          </b-button>
        </div>
      </template>
      <b-table
        ref="documentsFormatsTable"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        small
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="Fields.DocumentFormatFields"
        :items="myProvider"
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(file)="data">
          <amg-icon
            icon="FilePdfIcon"
            class="cursor-pointer mr-1 text-danger"
            size="17"
            v-b-tooltip.hover.right="'View File'"
            @click="viewFile(data.item)"
          />
        </template>
        <template #cell(extension)="data">
          <span class="text-uppercase">{{ data.item.extension }}</span>
        </template>
        <template #cell(format_history)="data">
          <b-badge variant="light-primary" v-if="data.item.format_history >0">
            {{ data.item.format_history }}
          </b-badge>
          <b-badge variant="light-secondary" v-else>
            {{ data.item.format_history }}
          </b-badge>
        </template>
        <template #cell(created_by)="data">
          <span>{{ data.item.created_by }}</span> <br />
          <span>{{ data.item.created_at }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-1 text-info"
              size="17"
              @click="openModalEdit(data.item)"
            />

            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="17"
              @click="deleteDocumentFormat(data.item)"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>
    <modal-document-format
      v-if="openModalDocumentFormat"
      :formatId="formatId"
      @close="openModalDocumentFormat = false"
      @save="$refs['documentsFormatsTable'].refresh()"
    />
  </div>
</template>
<script>
import Fields from "@/views/debt-solution/views/settings/views/documents-config/document-formats/data/fields.data.js";
import filter from "@/views/debt-solution/views/settings/views/documents-config/document-formats/data/filters.data.js";
import modalDocumentFormat from "@/views/debt-solution/views/settings/views/documents-config/document-formats/components/modal/ModalDocumentFormat.vue";
import DocumentFormatService from "@/views/debt-solution/views/settings/views/documents-config/document-formats/service/document-format.service.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    modalDocumentFormat,
  },
  data() {
    return {
      openModalDocumentFormat: false,
      Fields,
      isBusy: false,
      FilterSlot: {
        filter,
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
    };
  },
  async mounted() {
    await this.A_GET_DOCUMENT_ACTIVES({ type: "ANSWER" });
    this.FilterSlot.filter[2].options = this.G_DOCUMENT_ACTIVES;
  },
  computed: {
    ...mapGetters({
      G_DOCUMENT_ACTIVES: "DSCourtInfoDocumentSettingsStore/G_DOCUMENT_ACTIVES",
    }),
  },
  methods: {
    ...mapActions({
      A_GET_DOCUMENT_ACTIVES:
        "DSCourtInfoDocumentSettingsStore/A_GET_DOCUMENT_ACTIVES",
    }),

    openModalCreate() {
      this.formatId = null;
      this.openModalDocumentFormat = true;
    },

    openModalEdit(item) {
      this.formatId = item.id;
      this.openModalDocumentFormat = true;
    },

    async myProvider(ctx) {
      try {
        this.addPreloader();

        let order = ctx.sortDesc ? "desc" : "asc";
        let orderBy = ctx.sortBy ? ctx.sortBy : "id";
        order = ctx.sortBy ? order : "desc";

        const data = await DocumentFormatService.getDocumentsFormat({
          serach_text: this.FilterSlot.filterPrincipal.model,
          page: this.FilterSlot.paginate.currentPage,
          perPage: this.FilterSlot.paginate.perPage,
          order_by: orderBy,
          order: order,
          date_from: this.FilterSlot.filter[0].model,
          date_to: this.FilterSlot.filter[1].model,
          document_type: this.FilterSlot.filter[2].model,
        });
        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.startPage = data.from ? data.from : 0;
        this.FilterSlot.toPage = data.to ? data.to : 0;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async viewFile(item) {
      try {
        const data = await DocumentFormatService.getDocumentFormatById({
          id: item.id,
        });
        if (data[0].url) {
          window.open(data[0].url, "_blank");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async deleteDocumentFormat(item) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          await DocumentFormatService.deleteDocumentFormat({
            id: item.id,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Document format deleted successfully"
          );
          this.$refs["documentsFormatsTable"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped></style>