<template>
  <b-modal
    ref="modal-format-document"
    :title="formatId ? 'EDIT FORMAT' : 'ASSIGN A NEW FORMAT'"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row class="px-2">
        <b-col md="6">
          <b-form-group label="Name:">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1|max:255"
            >
              <b-form-input
                v-model="form.name"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Name {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Document Type:">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-select
                clearable
                label="name"
                placeholder="Select document Type..."
                v-model="form.document_type"
                :options="G_DOCUMENT_ACTIVES"
                :reduce="(option) => option.id"
                :state="errors.length > 0 ? false : null"
                :class="errors[0] ? 'select__error' : ''"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Document Type {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Document File:">
            <validation-provider
              v-slot="{ errors }"
              :rules="formatId ? '' : 'required'"
            >
              <b-input-group>
                <b-input-group-append
                  is-text
                  v-if="!file_check && fileExtension == 'pdf'"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="EyeIcon"
                    v-b-tooltip.hover.left="'Preview File'"
                    @click="openPreviewPdf"
                  />
                  
                </b-input-group-append>
                <b-form-file
                  v-model="form.document_file"
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] || file_check ? 'select__error' : ''"
                  accept=".pdf"
                  @change="loadFile"
                />
              </b-input-group>
              <span v-if="errors[0]" class="text-danger mt-1"
                >Document File {{ errors[0] }}</span
              >
              <span v-if="file_check" class="text-danger mt-1"
                >Only PDF is accepted</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="save()" />
    </template>
    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-file="pdfFile"
      :pdf-name="pdfName"
      :pdfUrl="pdfFileUrl"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </b-modal>
</template>
<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import DocumentFormatService from "@/views/debt-solution/views/settings/views/documents-config/document-formats/service/document-format.service.js";
import { mapGetters, mapActions } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";

export default {
  components: {
    buttonSave,
    buttonCancel,
    PreviewPdf,
  },
  props: {
    formatId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showPreviewPdf: false,
      file_check: false,
      fileExtension: "",
      form: {
        formatId: "",
        name: "",
        document_type: null,
        document_file: null,
      },
    };
  },
  async mounted() {
    this.toggleModal("modal-format-document");
    await this.A_GET_DOCUMENT_ACTIVES({ type: "ANSWER" });
    if (this.formatId) {
      this.getDocumentFormatById();
      this.form.formatId = this.formatId;
    }
  },
  computed: {
    ...mapGetters({
      G_DOCUMENT_ACTIVES: "DSCourtInfoDocumentSettingsStore/G_DOCUMENT_ACTIVES",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      A_GET_DOCUMENT_ACTIVES:
        "DSCourtInfoDocumentSettingsStore/A_GET_DOCUMENT_ACTIVES",
    }),

    close() {
      this.$emit("close");
    },
    openPreviewPdf() {
      this.showPreviewPdf = true;
    },
    loadFile(event) {
      const file = event.target.files[0];
      if (file) {
        const fileName = file.name;
        this.fileExtension = fileName.split(".").pop().toLowerCase();
        if (this.fileExtension !== "pdf") {
          this.file_check = true;
          return;
        }
        this.file_check = false;
        this.pdfFileUrl = null;
        this.pdfName = fileName;
        this.pdfFile = file;
      } else {
        this.file_check = false;
        this.fileExtension = "";
      }
    },

    async save() {
      try {
        const result = await this.$refs.form.validate();
        this.addPreloader();
        if (result && !this.file_check) {
          const formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("document_type", this.form.document_type);
          formData.append("document_file", this.form.document_file);
          formData.append("formatId", this.form.formatId);
          formData.append("currentUser", this.currentUser.user_id);
          await DocumentFormatService.createOrUpdateDocumentFormat(formData);
          this.showSuccessSwal("Document format saved successfully");
          this.close();
          this.$emit("save");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getDocumentFormatById() {
      try {
        const data = await DocumentFormatService.getDocumentFormatById({
          id: this.formatId,
        });
        this.form.name = data[0].name;
        this.form.document_type = data[0].document_id;
        if (data[0].url) {
          this.file_check = false;
          this.fileExtension = "pdf";
          this.pdfFile = null;
          this.pdfName = data[0].name;
          this.pdfFileUrl = data[0].url;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
.input-group-text {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  border-radius: 0.357rem 0 0 0.357rem !important;
}
</style>